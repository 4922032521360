import $ from 'jquery';

var swiper = new Swiper(".heroHeaderSwiper", {
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
      clickable: true,
    },
});

// Toggle Menu - Toggle Menu Function
jQuery('#toggle').click(function() {
  jQuery(this).toggleClass('active');
  jQuery('.main-menu').toggleClass('open');
  jQuery('html').toggleClass('menu-overflow');
});

jQuery(".main-menu li.menu-item-has-children").append("<span class='dot-triggle text-white'><i class='fa fa-caret-down'></i></span>"),
jQuery(".dot-triggle").on("click",(function(e){
    e.preventDefault();
    var t=jQuery(this).siblings(".main-menu .dropdown-menu").toggleClass("menu-active-mobile");
    jQuery(".main-menu .dropdown-menu.active-team").not(t).removeClass("menu-active-mobile");
    var i=jQuery(this).closest(".dot-triggle").toggleClass("dot-active-mobile");
    jQuery(".dot-triggle.active-team").not(i).removeClass("dot-active-mobile")
}));

jQuery(".navbar-nav li.menu-item-has-children > a").on("click",(function(e){
    e.preventDefault();
}));